import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

class Drinks extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return (
            <div className="container">
                <h1 className="text-center">Wähle Deinen Freedrink</h1>
                <div className="row mt-5">
                    <div className="col">
                        <Card>
                            <Card.Body>
                                <Card.Title>Sex on the Beach</Card.Title>
                                <Button variant="primary">Jetzt bestellen</Button>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col">
                        <Card>
                            <Card.Body>
                                <Card.Title>Kiwi Twist</Card.Title>
                                <Button variant="primary">Jetzt bestellen</Button>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col">
                        <Card>
                            <Card.Body>
                                <Card.Title>Saver Sex</Card.Title>
                                <Button variant="primary">Jetzt bestellen</Button>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Drinks;