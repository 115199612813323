import React, { Component } from 'react';

class ThankYou extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return (
            <>
                <div className="container">
                    <h1 className="center">Vielen Dank!</h1>
                    <p>Wir haben Deine Bestellung erhalten. Deine Bestellung sollte demnächst an der Abholstation bereit stehen!</p>
                </div>
            </>
        );
    }
}
 
export default ThankYou;