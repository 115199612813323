import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

class Event extends Component {
    constructor(props) {
        super(props);
    }
    finalizeOrder() {
        fetch( 'https://api.beachclub-schwerin.de/api/order?orderID=' + this.state.orderID + '&freeDrink=' + this.state.freeDrink, {
            method: "POST"
        } )
        .then( (response) => response.json() )
        .then( (data) => {
            if( data.status == 'success' ) {
                this.setState({ step: 4 });
            } else {
                alert( 'Achtung! Dein Freedrink wurde bereits eingelöst!' );
            }
        } )
        .catch( (error) => console.log(error) );
    }
    checkOrder( orderID ) {
        fetch( 'https://api.beachclub-schwerin.de/api/verify_number?number=' + orderID + '/', {
            method: "POST",
        } )
        .then( (response) => response.json() )
        .then( (data) => {
            if( data.status != 'success' ) {
                alert( 'Bestellnummer nicht gefunden' );
            } else {
                this.setState( { step: 2 } );
            }
            console.log(data);
        } )
        .catch( (error) => console.log(error) );
    }
    componentDidMount() {
        fetch( 'https://api.beachclub-schwerin.de/api/event/', {
            method: "GET"
        } )
        .then( (response) => response.json() )
        .then( (data) => {
            if( data.status == 'success' ) {
                this.setState({ eventTitle: data.event.eventTitle, eventImage: data.event.eventImage, loading: false });
            } else {
                console.log('No upcoming Events found!');
            }
        } )
        .catch( (error) => console.log(error) );
    }
    state = {
        orderID: '',
        eventTitle: '',
        eventImage: '',
        loading: true,
        step: 1,
        freeDrink: '',
        freeDrinkText: ''
    }
    render() { 
        return (
            <>
                {this.state.loading == true ?
                    <h1 className="text-center">Lädt...</h1>
                    :
                    <div className="container pt-5 pb-5">
                        {this.state.step == 1 &&
                            <>
                                <h1 className="text-center">{this.state.eventTitle}</h1>
                                <p className="text-center mt-5">
                                    <img className="img-fluid rounded" src={this.state.eventImage} alt="Placeholder"/>
                                    <div className="text-center" style={{maxWidth: '600px', margin: '0 auto'}}>
                                        <InputGroup className="mb-3 mt-5">
                                            <Form.Control
                                            aria-describedby="basic-addon1"
                                            placeholder="Deine Bestellnummer"
                                            type="number"
                                            onChange={(e) => this.setState({ orderID: e.target.value })}
                                            />
                                            <Button variant="primary" onClick={() => this.checkOrder( this.state.orderID )} id="button-addon1">
                                                Einlösen
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </p>
                            </>
                        }
                        {this.state.step == 2 &&
                            <>            
                                <h1 className="text-center">Wähle Deinen Freedrink</h1>
                                <div className="mb-3">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>Sex on the Beach</Card.Title>
                                            <Button variant="primary" onClick={() => this.setState({ step: 3, freeDrink: 1, freeDrinkText: 'Sex on the Beach' })}>Jetzt bestellen</Button>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="mb-3">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>Kiwi Twist</Card.Title>
                                            <Button variant="primary" onClick={() => this.setState({ step: 3, freeDrink: 2, freeDrinkText: 'Kiwi Twist' })}>Jetzt bestellen</Button>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>Saver Sex</Card.Title>
                                            <Button variant="primary" onClick={() => this.setState({ step: 3, freeDrink: 3, freeDrinkText: 'Saver Sex' })}>Jetzt bestellen</Button>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </>
                        }
                        {this.state.step == 3 &&
                            <>
                                <h1 className="text-center">Bestellübersicht</h1>
                                <p className="mt-5 mb-5">
                                    <ListGroup>
                                        <ListGroup.Item>Name: <strong>Name wie auf Deinem Ticket</strong></ListGroup.Item>
                                        <ListGroup.Item>Freigetränk: <strong>{this.state.freeDrinkText}</strong></ListGroup.Item>
                                    </ListGroup>
                                </p>
                                <div className="row">
                                    <div className="col">
                                        <Button variant="outline-primary" onClick={() => this.setState({ step: 2, freeDrink: '' })} className="w-100 d-block">
                                            Zurück
                                        </Button>
                                    </div>
                                    <div className="col">
                                        <Button variant="primary" onClick={() => this.finalizeOrder()} className="w-100 d-block">
                                            Jetzt bestellen
                                        </Button>
                                    </div>
                                </div>
                            </>
                        }
                        {this.state.step == 4 &&
                            <>
                                <h1 className="center">Vielen Dank!</h1>
                                <p>Wir haben Deine Bestellung erhalten. Deine Bestellung steht gleich an der Abholstation bereit!</p>
                            </>
                        }
                    </div>
                }
            </>
        );
    }
}
 
export default Event;