import React, { Component } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';

class Order extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return (
            <>
                <div className="container">
                    <h1 className="text-center">Bestellübersicht</h1>
                    <p className="mt-5 mb-5">
                        <ListGroup>
                            <ListGroup.Item>Name: <strong>Aaron</strong></ListGroup.Item>
                            <ListGroup.Item>Freigetränk: <strong>Sex on the Beach</strong></ListGroup.Item>
                        </ListGroup>
                    </p>
                    <div className="row">
                        <div className="col">
                            <Button variant="outline-primary" className="w-100 d-block">
                                Zurück
                            </Button>
                        </div>
                        <div className="col">
                            <Button variant="primary" className="w-100 d-block">
                                Jetzt bestellen
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
 
export default Order;