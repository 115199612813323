import Drinks from './components/Drinks';
import Event from './components/Event';
import Order from './components/Order';
import ThankYou from './components/ThankYou';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <div className="container">
        <Event/>
      </div>
    </div>
  );
}

export default App;
